"use client";

import { useEffect } from "react";
declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

export type AdsConfig = {
  client: string;
  src: string;
  slot: string;
  format?: string;
  responsive?: boolean;
};

interface AdSenseProps {
  adsConfig: AdsConfig;
}

export default function AdSense({ adsConfig }: AdSenseProps) {
  useEffect(() => {
    if (typeof window !== "undefined" && adsConfig) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `${adsConfig.src}?client=${adsConfig.client}`;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);

      script.onload = () => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
          console.error("AdSense initialization failed:", error);
        }
      };

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [adsConfig]);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={adsConfig.client}
      data-ad-slot={adsConfig.slot}
      data-ad-format={adsConfig.format || "auto"}
      data-full-width-responsive={adsConfig.responsive ? "true" : "false"}
    ></ins>
  );
}
