import { MdOutlineFlight } from "react-icons/md";
function AirportInfo({ item, onMouseDown }) {
  const { airportName, cityName, airportCode } = item;
  return (
    <li
      onMouseDown={onMouseDown}
      className="flex justify-between items-center gap-2 font-medium p-2 text-sm text-gray-600 cursor-pointer"
    >
      <div className="flex gap-2 items-center">
        <MdOutlineFlight className="text-gray-600 !text-base" />
        <span>
          {airportName ? airportName : ""},{cityName ? cityName : ""}{" "}
        </span>
      </div>
      <div className="text-gray-400">{airportCode ? airportCode : ""}</div>
    </li>
  );
}

export default AirportInfo;
